
import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from "axios";
import validator from 'validator';


function SectionContactForm() {
    const addfontsz={
        fontSize: '12pt'
    };
    const ptxtsize={
        fontSize: '12pt'
    };
    const pdtop={
        paddingTop: '20px'
    };
    const displaysts={
        display: 'none'
    };



    const h4txt={
        marginBottom: '30px',
        textAlign: 'center'
    };

    const errtxt={
        fontSize: 'small'
    };

    let [name,setName]=useState("");
    let [isname,setIsname]=useState(false);

    let [email,setEmail]=useState("");
    let [isemail,setIsemail]=useState(false);

    let [phone,setPhone]=useState("");
    let [isphone,setIsphone]=useState(false);

    let [subject,setSubject]=useState("");
    let [issubject,setIssubject]=useState(false);

    let [content,setContent]=useState("");
    let [iscontent,setIscontent]=useState(false);

    let [isbtn,setIsbtn]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcolor,setMsgcolor]=useState("");
    let [isalert,setIsalert]=useState(false);

    async function sendmessage() {
        var flag=1;

        if (name.length==0 || name.trim()=="") {
            setIsname(true);
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setIsemail(true);
            flag=0;
        }
        if (phone.length<10 || phone.trim()=="") {
            setIsphone(true);
            flag=0;
        }
        if (subject.length==0 || subject.trim()=="") {
            setIssubject(true);
            flag=0;
        }
        if (content.length==0 || content.trim()=="") {
            setIscontent(true);
            flag=0;
        }

        if (flag==1) {
            var fd=new FormData();
            fd.append("name",name);
            fd.append("email",email);
            fd.append("phone",phone);
            fd.append("subject",subject);
            fd.append("content",content);

            var resp=await axios.post("https://safalata.net/safalatalara/api/addcontact",fd);
            var data=resp.data;

            setName("");

            setMsg(data.msg);
            setIsalert(data.sendmsg);
            setMsgcolor(data.msgcolor);

            setIsbtn(true);            
        }

    }

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return(
        <>
        
        <section id="contact" className="contact">
        <div className="container">

            <div className="row justify-content-center" data-aos="fade-up">

            <div className="col-lg-10">

                <div className="info-wrap">
                <div className="row">
                    <div className="col-lg-6 info">
                    
                    <p><img src="../assets/img/logo.png" alt="" className="img-fluid"/></p>
                    <h4 style={addfontsz}>(An institute under M/S Shraddha Group of Education)</h4>
                    <p style={ptxtsize}>195, Kailash Das Road, Gauripur,<br/>Nearest Railway Station-Naihati
                    <br/>Dist - North 24 Parganas<br/>West Bengal - 743166</p>
                    </div>

                    <div className="col-lg-6 info mt-4 mt-lg-0" style={pdtop}>
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>dshaw0112@gmail.com</p>
                    <hr/>
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>9339764227</p>
                    <hr/>
                    <i className="bi bi-whatsapp"></i>
                    <h4>Whatsapp:</h4>
                    <p>8013181226</p>
                    </div>


                </div>
                </div>

            </div>

            </div>

            <div className="row mt-5 justify-content-center" data-aos="fade-up">
                <div className="col-lg-12">
                    <div className="container">
                        <h4 style={h4txt}>Contact Us</h4>
                        <div className='text-center'>{isalert?<div className={msgcolor} style={{margin: 30}}>{msg}</div>:''}</div>
                        <div className="row">
                            <div className="col-md-6 form-group">
                            <input type="text" className="form-control" placeholder="Your Name" onChange={(ev)=>{
                                setName(ev.target.value);
                                if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                    setIsname(false);
                                }
                            }} />
                            {isname?<div className="text text-danger" style={errtxt}>contact name required</div>:''}
                            </div>
                            <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input type="email" className="form-control" placeholder="Your Email" onChange={(ev)=>{
                                setEmail(ev.target.value);
                                if (validator.isEmail(email)) {
                                    setIsemail(false);
                                }
                            }} />
                            {isemail?<div className="text text-danger" style={errtxt}>contact email required</div>:''}
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <input type="tel" minLength={10} maxLength={10} className="form-control" placeholder="Your Phone" onChange={(ev)=>{
                                setPhone(ev.target.value);
                                if (ev.target.value.length==10 && ev.target.value.trim()!="") {
                                    setIsphone(false);
                                }
                            }} />
                            {isphone?<div className="text text-danger" style={errtxt}>contact phone required</div>:''}
                        </div>
                        <div className="form-group mt-3">
                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={(ev)=>{
                                setSubject(ev.target.value);
                                if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                    setIssubject(false);
                                }
                            }} />
                            {issubject?<div className="text text-danger" style={errtxt}>subject required</div>:''}
                        </div>
                        <div className="form-group mt-3">
                            <textarea className="form-control" rows="5" placeholder="Message" onChange={(ev)=>{
                                setContent(ev.target.value);
                                if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                    setIscontent(false);
                                }
                            }} />
                            {iscontent?<div className="text text-danger" style={errtxt}>message required</div>:''}
                        </div>
                        {/* <div className="my-3">
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div> */}
                        <div className="form-group mt-3">
                            <div className="text-center"><button className="btn btn-primary" onClick={sendmessage} disabled={isbtn} >Send Message</button></div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        </section>

        </>
    );
}
export default SectionContactForm;