import {Link} from 'react-router-dom';

import Header from '../include/Header';
//import Heroslider from '../include/Heroslider';
import SectionAbout from '../include/SectionAbout';


import Footer from '../include/Footer';

const margincenter={
    marginLeft: 'auto',
    marginRight: 'auto'
};

const btnsize={
    fontSize: 'x-large'
};

const mgtop={
    marginTop: '112px'
};

const imground={
    borderRadius: '50%',
    width: '200px'
};

function Academy() {
    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

        <section id="breadcrumbs" className="breadcrumbs" style={mgtop}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Academy</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>Academy</li>
                </ol>
                </div>

            </div>
        </section>
        <SectionAbout/>

        <div className="section">
        <div className="container">
            <div className="row mb-5 justify-content-center text-center">
            <div className="col-lg-4 mb-5">
                <h2 className="section-title-underline mb-1">
                <span>Our Teachers</span>
                </h2>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-12 col-md-6 mb-5 mb-lg-5">
                <div className="feature-1 border person text-center">
                    <img src="../../assets/img/teacher.jpg" alt="Image" className="img-fluid" style={imground}/>
                    <div className="feature-1-content">
                        <h2>Dharmendra Kr. Shaw</h2>
                        <span className="position mb-3 d-block">Teacher</span>    
                        <p>M.A. (English and Hindi)</p>
                        <p>P.G.D.T., B.Ed., P.G.D.P.M &amp; I.R.</p>
                        <p>14 Years Experience of Teaching.</p>
                    </div>
                </div>
            </div>
            
            </div>
        </div>
        </div>
        
        <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title" data-aos="fade-up">
                    <h2>About <strong>Our Institute</strong></h2>
                    <p></p>
                </div>

                <div className="row" style={margincenter}>
                <div className="col-lg-3 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div>
                <div className="col-lg-7 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon-box iconbox-blue">
                    <h4><Link to="">आपकी सफ़लता ही हमारी प्राथमिकता है।</Link></h4>
                    <h4><Link to="">We prepare for Translator and Rajbhasha Adhikary..</Link></h4>
                    <h4> &nbsp;</h4>
                    <Link to="/academy" className="btn btn-success" style={btnsize}>Read More</Link>
                    </div>
                </div>
                <div className="col-lg-2 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div>
                </div>

            </div>
        </section>

        </main>
        <Footer/>
        </>
    );
}
export default Academy;