import react, { useRef, useEffect, useState } from "react";

import {Link} from 'react-router-dom';

import $ from 'jquery';

import axios from 'axios';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SchoolIcon from '@mui/icons-material/School';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const styles = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PackagePlanList() {

    let [exampackages,setExampackages]=useState([]);
    async function getExamPackages() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getexampackages");
        var data=resp.data;
        setExampackages(data.obj);
    }

    useEffect(()=>{
        // setIsOpen(true);
        getExamPackages();
    },[])


    return(
        <>

            <Container>
                {/* <Box sx={{ marginTop: -7, marginLeft: '10px', marginRight: '10px', }}> */}
                <Box sx={{ marginLeft: '10px', marginRight: '10px' }} style={{marginTop: '130px'}}>
                    <Typography align="center" sx={{color: '#800000',fontSize: '1.7rem', fontWeight: 900, pb: 2}}>MOCK TEST PACKAGES</Typography>
                </Box>
                <Grid container>
                    

                    {exampackages.map((e)=>
                    <Grid item sm={3} xs={12} p={1} pb={1}>
                        <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" className='hbtn btn-bluebg gridwidth' align={'center'} onClick={()=>{
                            window.open("https://studportal.safalata.net/","_blank");
                        }}>
                            <Grid height={'190px'}>
                                <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 2}}>{e.package_name}</Typography>
                                <Box sx={{ marginTop: 1.5, borderRadius: 2, marginLeft: '10px', marginRight: '10px', background: '#ffffff'}}>
                                    <Typography sx={{color: '#3d2be0',fontSize: '1.2rem', fontWeight: 900}}>Hindi & English</Typography>
                                    
                                </Box>
                                <Box sx={{ marginTop: 0.2, marginLeft: '10px', marginRight: '10px'}}>
                                    <Typography><TouchAppIcon sx={{color: '#fff',fontSize: '2rem', fontWeight: 900}} /></Typography>
                                    
                                </Box>
                            </Grid>
                            <Box sx={{borderRadius: 2}} pt={-3} className='hbtn btn-whitebg gridwidth' align={'center'}>
                            <Typography sx={{color: '#3d2be0',fontSize: '1.4rem', fontWeight: 900}}>(MCQ - {e.total_mcq})</Typography>
                            </Box>
                        </Box>
                        
                    </Grid>
                    )}


                    <Grid item sm={12} xs={12} p={1} className="gridwidth">
                        <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" className='hbtn btn-testpinkbg gridwidth' align={'center'} onClick={()=>{
                            window.open("https://studportal.safalata.net/","_blank");
                        }}>    
                        <Grid height={'200px'}>
                            <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 4}}>Mock Test</Typography>
                            <Box sx={{ marginTop: 4, borderRadius: 2, marginLeft: '15px', marginRight: '15px', background: '#ffffff', width: '200px'}}><Typography sx={{color: '#b6048f',fontSize: '1.8rem', fontWeight: 900}}>FREE</Typography></Box>
                            <Box sx={{ marginTop: 0.2, marginLeft: '10px', marginRight: '10px'}}>
                            <Typography><TouchAppIcon sx={{color: '#fff',fontSize: '2rem', fontWeight: 900}} /></Typography>
                            
                            </Box>
                        
                        </Grid>                
                        
                        </Box> 

                        
                        
                    </Grid>

                    
                    
                </Grid>

            </Container>

            </>
    );
}
export default PackagePlanList;