//import logo from './logo.svg';
import './App.css';

import Login from './layout/Login';
import Register from './layout/Register';

import Home from './layout/Home';
import Academy from './layout/Academy';
import Admission from './layout/Admission';
import Course from './layout/Course';
import Successstory from './layout/Successstory';
import Contact from './layout/Contact';
import Privacy from './layout/Privacy';
import TermsandConditions from './layout/TermsAndConditions';
import RefundCancellationPolicy from './layout/RefundCancellationPolicy';

import {BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {
  return (
    
    <>
    <BrowserRouter>
    <Routes>
      {/* <Route exact path="/studportal/login" element={<Login/>}/> */}
      <Route exact path="/login" element={<Login/>}/>
      <Route exact path="/register" element={<Register/>}/>

      <Route exact path="/" element={<Home/>}/>
      <Route exact path="/academy" element={<Academy/>}/>
      <Route exact path="/admission" element={<Admission/>}/>
      <Route exact path="/course" element={<Course/>}/>
      <Route exact path="/successstory" element={<Successstory/>}/>
      <Route exact path="/contact" element={<Contact/>}/>
      <Route exact path="/privacypolicy" element={<Privacy/>}/>
      <Route exact path="/terms-and-conditions" element={<TermsandConditions/>}/>
      <Route exact path="/refund-cancellation-policy" element={<RefundCancellationPolicy/>}/>

    </Routes>
    </BrowserRouter>
    </>

  );
}

export default App;
