import {Link} from 'react-router-dom';

import Header from '../include/Header';

import Footer from '../include/Footer';

// import { useState } from 'react';

import axios from 'axios';
import validator from 'validator';

import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

// For Modal
import Modal from 'react-modal';
// For Modal

import {useNavigate} from "react-router-dom";

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
// FOR RAZORPAY PAYMENT GATE INTEGRATION


function Register() {

    const Razorpay = useRazorpay();
    let navigate = useNavigate();

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    const formgroup2={
        marginTop: '10px',
        marginBottom: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const [progress, setProgress] = useState(0);

    // const baseurl="https://www.safalata.net/safalatalara";

    const mgtop={
        marginTop: '112px'
    };

    let [studname,setStudname]=useState("");
    let [enrollid,setEnrollid]=useState("");

    let [name,setName]=useState("");
    let [dob,setDob]=useState("");
    let [phone,setPhone]=useState("");
    let [email,setEmail]=useState("");
    let [city,setCity]=useState("");
    let [qualification,setQualification]=useState("");
    let [extra,setExtra]=useState("");
    let [password,setPassword]=useState("");
    let [password2,setPassword2]=useState("");

    let [studentmode,setStudentmode]=useState("");
    let [isstudentmode,setIsstudentmode]=useState(false);

    let [nameerr,setNameerr]=useState("");
    let [doberr,setDoberr]=useState("");
    let [phoneerr,setPhoneerr]=useState("");
    let [emailerr,setEmailerr]=useState("");
    let [cityerr,setCityerr]=useState("");
    let [qualificationerr,setQualificationerr]=useState("");
    let [extraerr,setExtraerr]=useState("");
    let [passworderr,setPassworderr]=useState("");

    let [img,setImg]=useState(null);

    let [isquali,setIsquali]=useState(false);
    let [isextra,setIsextra]=useState(false);

    let [isbtn,setIsbtn]=useState(true);
    let [ischeck,setIscheck]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");

    let [msg2,setMsg2]=useState("");
    let [msg3,setMsg3]=useState("");

    let [mapassingyrlabel,setMapassingyrlabel]=useState("");
    let [mapassingyr,setMapassingyr]=useState("");
    let [ismapassing,setIsmapassing]=useState(true);

    let [extrapassingyrlabel,setExtrapassingyrlabel]=useState("");
    let [extrapassingyr,setExtrapassingyr]=useState("");
    let [isextrapassing,setIsextrapassing]=useState(true);

    let [captiontext,setCaptiontext]=useState("");

    let [instruction,setInstruction]=useState("");
    let [isinstruction,setIsinstruction]=useState(false);
    let [instructiontext,setInstructiontext]=useState("");
        
    let [loader,setLoader]=useState(false);

    let [divshow,setDivshow]=useState("block");

    async function formsubmit() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // console.log(studentmode);

        var flag=1;

        if (name.length==0) {
            setNameerr("Required Name");
            flag=0;
        }
        if (dob.length==0) {
            setDoberr("Required Date of Birth");
            flag=0;
        }
        if (phone.length<10) {
            setPhoneerr("Required Valid Phone Number");
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setEmailerr("Required Valid Email ID");
            flag=0;
        }
        if (city.length==0) {
            setCityerr("Required City");
            flag=0;
        }
        // if (isquali==false) {
        //     setQualificationerr("Required Qualification");
        //     flag=0;
        // }
        // if (studentmode.length=="") {
        //     setIsstudentmode(true);
        //     flag=0;
        // }
        // if (isextra==false) {
        //     setExtraerr("Required PG Course");
        //     flag=0;
        // }
        if (password.length>0 && password2.length>0) {
            if (password.length<6 || password2.length<6 && password!=password2) {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
            } else if (password.length>=6 && password2.length>=6 && password!=password2) {
                setPassworderr("Password does not match");
                flag=0;
            }
        } else {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
        }

        // if (instruction.length==0) {
        //     setIsinstruction(true);
        //     flag=0;
        // }

        if (flag==1) {
            // setProgress(100);
            setProgress(progress + 200);
            // setInterval(()=>{
            //     setProgress(progress + 10);
            // },1000);           

            setLoader(true);
            
            setIsbtn(true);

            var fd=new FormData();
            fd.append("name",name);
            fd.append("dob",dob);
            fd.append("phone",phone);
            fd.append("email",email);
            fd.append("city",city);
            // fd.append("qualification",qualification);
            // fd.append("mapassingyr",mapassingyr);
            // fd.append("extra",extra);
            // fd.append("extrapassingyr",extrapassingyr);
            fd.append("password",password);
            fd.append("img",img);
            // fd.append("studentmode",studentmode);
            // var resp=await axios.post({baseurl}+"/api/studregister",fd);
            // if (studentmode==1) {
            //     var resp=await axios.post("https://www.safalata.net/safalatalara/api/studregister",fd);
            //     var data=resp.data;
            // } else if (studentmode==2) {
            //     var resp=await axios.post("https://www.safalata.co.in/safalatalara/api/studregister",fd);
            //     var data=resp.data;
            // }
            var resp=await axios.post("https://www.safalata.net/safalatalara/api/studregister",fd);
            var data=resp.data;

            setMsg(data.msg);
            setMsg2(data.msg2);
            setMsg3(data.msg3);

            setMsgcode(data.msgcode);
            setMsgcolor(data.msgcolor);

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

            if (data.msgcode==1) {
                setIsbtn(true);
                setIscheck(true);
                setStudname(data.studname);
                setEnrollid(data.enrollid);

                setDivshow("none");

                setName("");
                setDob("");
                setPhone("");
                setEmail("");
                setCity("");
                // setQualification("");
                // setExtra("");
                setPassword("");
                setPassword2("");
                setNameerr("");
                setDoberr("");
                setPhoneerr("");
                setEmailerr("");
                setCityerr("");
                // setQualificationerr("");
                // setExtraerr("");
                setPassworderr("");
                // setIsstudentmode(false);

                
            }

            setLoader(false);
        }

    }


    let [fees, setFees]=useState("");

    async function getnoticeboard() {
        setLoader(true);
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setFees(data.fees);
        setLoader(false);
    }



    //PAYMENT GATEWAY RAZORPAY INTEGRATION
    function pay_online(oid,st,name,enrollid) {
        var name=name;
        // var amt=Number(st);
        var amt=Number(st);
        // var eid=enrollid;
        $.ajax({
              type:'post',
              url:'https://safalata.net/safalatalara/api/gopayment',
              data:"amt="+amt+"&name="+name,
              success:function(result){
                  var options = {
                        "key": "rzp_live_QrhcwcL0TIPoRe", 
                        "amount": amt*100, 
                        "currency": "INR",
                        "name": "Safalata - Shraddha Group of Education",
                        "description": "Exam Fees",
                        "image": "https://www.safalata.net/safalatalara/payimage/payimg.jpg",
                        "handler": function (response){
                          $.ajax({
                              type:'post',
                              url:'https://safalata.net/safalatalara/api/gopayment',
                              data:"payment_id="+response.razorpay_payment_id+"&oid="+oid+"&enrollid="+enrollid+"&amt="+amt,
                              success:function(result){
                                //    window.location.href="/";
                                // let path = `/order/`+oid;
                                let path = `/thankyou`;
                                navigate(path);
                              }
                          });
                        }
                    };
                    var rzp1 = new Razorpay(options);
                    rzp1.open();
                }
        });
      }

      


    useEffect(()=>{
        // setIsOpen(true);
        getnoticeboard();
    },[])

    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

            {loader?
                <LoadingBar
                color='#0e00d1'
                progress={progress}
                transitionTime={20000}
                loaderSpeed={20000}
                waitingTime={20000}
                onLoaderFinished={()=>setProgress(0)}
                />
                :
                ''
            }

        <section id="breadcrumbs" className="breadcrumbs" style={mgtop}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Register</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>Register</li>
                </ol>
                </div>

            </div>
        </section>

        <section id="about-us" className="about-us">
            <div className="container" data-aos="fade-up">

                <div className="section-title" style={{display: divshow }}>
                    <h2>Student Registration</h2>
                </div>
                {/* <div className="form-group text-center" style={formgroup2}>
                    <button onClick={()=>{
                        setIsOpen(true);
                    }} className="btn btn-secondary">Please Read Instructions</button>
                </div> */}
                {msgcode>0?
                // <div className="col-md-12 form-group text-center" style={{marginTop: 40, marginBottom: 30, padding: 10}}>
                //     <div style={{fontSize: 'x-large', fontWeight: 700}} className={msgcolor}>{msg} <br/> 
                //     {msgcode==1?<button class="btn btn-primary" onClick={()=>{
                //         window.open("https://studportal.safalata.net/");
                //     }} style={{color: '#c70000'}}>Click Here To Login</button>:''}
                //     </div>
                // </div>
                <div className="col-md-12 form-group text-center" style={{marginBottom: 30, padding: 10}}>
                    {msgcode==1?
                    <div>
                    <div style={{fontSize: 'x-large', fontWeight: 700, marginBottom: '20px'}} className={msgcolor}>
                        <span style={{color: '#00ba10'}}>{msg}</span><br/><br/>
                        {msg2}<br/><br/>
                        {msg3}
                    </div>
                    </div>
                    :
                    <div style={{fontSize: 'x-large', fontWeight: 700, marginBottom: '20px'}} className={msgcolor}>{msg}</div>
                    }
                    {/* <div style={{fontSize: 'x-large', fontWeight: 700}} className={msgcolor}>Please click here for payment <br/>&#8377; {parseInt(fees)+'/-'} for 30 days <br/>  <br/> 
                    {msgcode==1?
                    <button className="btn btn-primary" onClick={()=>{
                        var oid = Math.random().toString(16).slice(2)
                        pay_online(oid,fees,studname,enrollid);
                        }} style={{fontSize: 'large'}}>PAY NOW</button>
                    :''}
                    </div> */}

                    <div style={{fontSize: 'x-large', fontWeight: 700}} className={msgcolor}>Please click here for student login<br/>  <br/> 
                    {msgcode==1?
                    <button className="btn btn-primary" onClick={()=>{
                        window.open("https://studportal.safalata.net/");
                        }} style={{fontSize: 'large'}}>LOGIN NOW</button>
                    :''}
                    </div>
                </div>
                :''}
                <div className="row content" style={{display: divshow }}>
                    <div className="col-lg-1 pt-4 pt-lg-0"></div>
                    <div className="col-lg-10 pt-4 pt-lg-0" data-aos="fade-left">

                        {/* <div className="row" style={{marginBottom: 40, border: '1px solid #999', padding: 20, borderRadius: '5px', backgroundColor: '#cdf7d4'}}>
                            <h5 className="card-title" style={{fontSize: 'x-large'}}>Examination Mode Section</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Student Examination Mode — <span style={{fontSize:'small', color:'red'}}>*</span></label>

                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="student_mode" id="student_mode2" onChange={(ev)=>{
                                        setStudentmode(ev.target.value);
                                        setIsstudentmode(false);
                                        if (ev.target.value>0) {
                                            setCaptiontext("Online / Offline Classes Are Not Included. Live Exam And Practice Exam Only.");
                                        } else {
                                            setCaptiontext("");
                                        }
                                    }} value="2" />
                                    <label className="form-check-label" for="student_mode2">
                                        LIVE EXAM + PRACTICE EXAM ONLY
                                    </label>
                                </div>
                                {captiontext.length>0?<div className='text large mt-2'>{captiontext}</div>:''}
                                {isstudentmode==true?<div className='text text-danger'>CHOOSE ANY ONE MODE AS PER YOUR REQUIRED</div>:''}
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: 40, border: '1px solid #999', padding: 20, borderRadius: '5px', backgroundColor: '#cdf7d4'}}>
                           <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Please Read And Agree This Instructions — <span style={{fontSize:'small', color:'red'}}>*</span></label>
           
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="instruction" id="instruction" onChange={(ev)=>{
                                        setInstruction(ev.target.value);
                                        setIsinstruction(false);
                                        if (ev.target.value>0) {
                                            setInstructiontext("इस वेबसाईट पर रु. 300/- का भुगतान करने पर 30 दिन के लिए प्रतिदिन कम से कम 4 tests series (200 हिंदी+ 200 अंग्रेजी) अभ्यास करने के लिए मिलेगा। जो छात्र Hindi Translator &amp; Rajbhasha Adhikari की तैयारी कर रहा है उनके लिए यह वेबसाईट बहुत ही उपयोगी है।");
                                        } else {
                                            setInstructiontext("");
                                        }
                                    }} value="1" />
                                    <label className="form-check-label" for="instruction">
                                        Read &amp; Agree This Instructions —
                                    </label>
                                </div>
                                {instructiontext.length>0?<div className='text large mt-2' style={{color: '#2a0080',}}>{instructiontext}</div>:''}
                                {isinstruction==true?<div className='text text-danger'>Please Read &amp; Agree Instructions</div>:''}
                            </div>
                        </div> */}

                        <div className="row" style={{marginBottom: 40, border: '1px solid #999', padding: 20, borderRadius: '5px'}}>
                            <h5 className="card-title" style={{fontSize: 'x-large'}}>Personal Information</h5>
                            
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Student Name <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="text" className="form-control form-control-lg" onChange={(ev)=>{
                                    setName(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setNameerr("");
                                    }
                                }} />
                                {name.length==0?<div className='text text-danger'>{nameerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Date of Birth <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="date" className="form-control form-control-lg" onChange={(ev)=>{
                                    setDob(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setDoberr("");
                                    }
                                }} />
                                {dob.length==0?<div className='text text-danger'>{doberr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Phone Number <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="tel" maxLength={10} className="form-control form-control-lg" onChange={(ev)=>{
                                    setPhone(ev.target.value);
                                    if (ev.target.value.length==10) {
                                        setPhoneerr("");
                                    }
                                }} />
                                {phone.length<10?<div className='text text-danger'>{phoneerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Email ID <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="email" className="form-control form-control-lg" onChange={(ev)=>{
                                    setEmail(ev.target.value);
                                    if (validator.isEmail(email)) {
                                        setEmailerr("");
                                    }                                    
                                }} />
                                {!validator.isEmail(email)?<div className='text text-danger'>{emailerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>City <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="city" className="form-control form-control-lg" onChange={(ev)=>{
                                    setCity(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setCityerr("");
                                    }
                                }} />
                                {city.length==0?<div className='text text-danger'>{cityerr}</div>:''}
                            </div>

                            {/* <hr style={{marginTop: 20}} />
                            <h5 className="card-title" style={{fontSize: 'x-large', marginTop: 10}}>Educational Qualification</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>M.A. in Hindi/English with Hindi and English as an Honours Paper/Elective Paper/Medium of studies at Degree(B.A.) Level — <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="qualification" id="qualification" onChange={(ev)=>{
                                        setQualification(ev.target.value);
                                        setIsquali(true);
                                        setMapassingyrlabel("Month & Year of Passing");
                                        setIsmapassing(false);
                                    }} value="1" />
                                    <label className="form-check-label" for="qualification">
                                        Completed
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="qualification" id="qualification2" onChange={(ev)=>{
                                        setQualification(ev.target.value);
                                        setIsquali(true);
                                        setMapassingyrlabel("Month & Year of Completion");
                                        setIsmapassing(false);
                                    }} value="2" />
                                    <label className="form-check-label" for="qualification2">
                                        Persuring
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="qualification" id="qualification3" onChange={(ev)=>{
                                        setQualification(ev.target.value);
                                        setIsquali(true);
                                        setMapassingyrlabel("");
                                        setIsmapassing(true);
                                        setMapassingyr("");
                                    }} value="3" />
                                    <label className="form-check-label" for="qualification3">
                                        Others
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <label className="form-label" for="qualification3">
                                        {mapassingyrlabel}
                                    </label>
                                    <input className="form-control" type="text" onChange={(ev)=>{
                                        setMapassingyr(ev.target.value);
                                    }} disabled={ismapassing} placeholder="mm/yyyy [e.g. 10/2017]" />
                                    
                                </div>
                                {isquali==false?<div className='text text-danger'>{qualificationerr}</div>:''}
                            </div>

                            <hr style={{marginTop: 20}} />
                            <h5 className="card-title" style={{fontSize: 'x-large', marginTop: 10}}>PG Diploma</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Post Graduation Diploma in Translation (P.G.D.T.) — <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="extra" id="extra" onChange={(ev)=>{
                                        setExtra(ev.target.value);
                                        setIsextra(true);
                                        setExtrapassingyrlabel("Month & Year of Passing");
                                        setIsextrapassing(false);
                                    }} value="1" />
                                    <label className="form-check-label" for="extra">
                                        Completed
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="extra" id="extra2" onChange={(ev)=>{
                                        setExtra(ev.target.value);
                                        setIsextra(true);
                                        setExtrapassingyrlabel("Month & Year of Completion");
                                        setIsextrapassing(false);
                                    }} value="2" />
                                    <label className="form-check-label" for="extra2">
                                        Persuring
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <input className="form-check-input" type="radio" name="extra" id="extra3" onChange={(ev)=>{
                                        setExtra(ev.target.value);
                                        setIsextra(true);
                                        setExtrapassingyrlabel("");
                                        setIsextrapassing(true);
                                        setExtrapassingyr("");
                                    }} value="3" />
                                    <label className="form-check-label" for="extra3">
                                        Others
                                    </label>
                                </div>
                                <div className="form-check" style={{fontSize: 'large'}}>
                                    <label className="form-label">
                                        {extrapassingyrlabel}
                                    </label>
                                    <input className="form-control" type="text" onChange={(ev)=>{
                                        setExtrapassingyr(ev.target.value);
                                    }} disabled={isextrapassing} placeholder="mm/yyyy [e.g. 10/2017]" />
                                    
                                </div>
                                {isextra==false?<div className='text text-danger'>{extraerr}</div>:''}
                            </div> */}

                            <hr style={{marginTop: 20}} />
                            <h5 className="card-title" style={{fontSize: 'x-large', marginTop: 10}}>Create Password</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>New Password <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                    <input type="password" className="form-control form-control-lg" onChange={(ev)=>{
                                        setPassword(ev.target.value);
                                        if (ev.target.value.length>=6 && password2.length>=6) {
                                            setPassworderr("");
                                        }
                                    }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Retype New Password <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                    <input type="password" className="form-control form-control-lg" onChange={(ev)=>{
                                        setPassword2(ev.target.value);
                                        if (ev.target.value.length>=6 && password.length>=6) {
                                            setPassworderr("");
                                        }
                                    }} />
                                    <div className='text text-danger'>{passworderr}</div>
                            </div>

                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Upload Profile Pic (Only .jpg, jpeg image upload) <span style={{fontSize:'small', color:'red'}}>(optional)</span></label>
                                    <input type="file" className="form-control form-control-lg" onChange={(ev)=>{
                                        setImg(ev.target.files[0]);
                                    }} accept='.jpg,.jpeg' />
                            </div>

                            <div className="form-check" style={{marginTop: 20}}>
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(ev)=>{
                                    if (ev.target.checked==true) {
                                        setIsbtn(false);
                                    } else {
                                        setIsbtn(true);
                                    }
                                }} disabled={ischeck} />
                                <label className="form-check-label" for="flexCheckDefault">
                                    I hereby declare that all the above information is correct and accurate. I solemnly declare that all the information furnished in this document is free of errors to the best of my knowledge and also I have read and agree to the terms of use.
                                </label>
                            </div>

                            <div className="col-md-12 form-group text-center" style={{marginTop: 40}}>
                                <input type="button" className="btn btn-success" value="REGISTER" disabled={isbtn} style={{fontSize: 'x-large'}} onClick={formsubmit} />
                            </div>
                            
                            {msgcode>0?
                            <div className="col-md-12 form-group text-center" style={{marginTop: 40}}>
                                <div style={{fontSize: 'large', fontWeight: 700}} className={msgcolor}>{msg} <br/> 
                                {msgcode==1?<button class="btn btn-primary" onClick={()=>{
                                    window.open("https://studportal.safalata.net/")
                                }} style={{color: '#c70000'}}>Click Here To Login</button>:''}
                                </div>
                            </div>
                            :''}

                        </div>
                    </div>
                    <div className="col-lg-1 pt-4 pt-lg-0"></div>
                </div>


            </div>
        </section>

        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::INSTRUCTIONS:::
            </div>
            <div className='form-group' style={formgroup}>
            इस वेबसाईट पर रु. 300/- का भुगतान करने पर 30 दिन के लिए प्रतिदिन कम से कम 4 tests series (200 हिंदी+ 200 अंग्रेजी) अभ्यास करने के लिए मिलेगा।<br/>जो छात्र Hindi Translator &amp; Rajbhasha Adhikari की तैयारी कर रहा है उनके लिए यह वेबसाईट बहुत ही उपयोगी है।
            </div>
            
            
            <div className="form-group text-center" style={formgroup}>
            <button onClick={()=>{
                setIsOpen(false);
            }} className="btn btn-danger">Close</button>
            </div>
            
        </Modal>

        </main>
        <Footer/>
        </>
    );
}
export default Register;