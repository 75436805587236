import {Link} from 'react-router-dom';

import Header from '../include/Header';
//import Heroslider from '../include/Heroslider';
import SectionTOS from '../include/SectionTOS';


import Footer from '../include/Footer';

const margincenter={
    marginLeft: 'auto',
    marginRight: 'auto'
};

const btnsize={
    fontSize: 'x-large'
};

const mgtop={
    marginTop: '112px'
};

const imground={
    borderRadius: '50%',
    width: '200px'
};

function TermsandConditions() {
    return(
        <>
        <Header/>
        {/* <Heroslider/> */}
        <main id="main">

        <section id="breadcrumbs" className="breadcrumbs" style={mgtop}>
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Terms &amp; Conditions</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>TOS</li>
                </ol>
                </div>

            </div>
        </section>
        <SectionTOS/>

        
        
        

        </main>
        <Footer/>
        </>
    );
}
export default TermsandConditions;