import {Link} from 'react-router-dom';

function SectionRefundCancellationPolicy() {
    return(
        <>
        <section id="about-us" class="about-us">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Refund &amp; Cancellation Policy</h2>
                </div>

                <div class="row content">
                    <div class="col-lg-12" data-aos="fade-right">
                    <div class="col-md-12">
                                <p class="mb-15-2" style={{fontWeight: '900', backgroundColor: '#fff1ab', padding: '5px'}}>Refund &amp; Cancellation Policy</p>
                                <p class="mb-15" style={{textAlign: 'justify'}}>You must inform the service provider within 48 hours through email to shraddha@safalata.net or by calling 8013181226/9339764227 if you decide not to take test & live series and please quote your enrollment id, generated at the time of registration. No cancellation fee applies, the total amount will be refunded within 3 working days. After 48 hours it may not be cancelled. We will not be able to refund the paid amount.</p>
                                
                        </div>
                    </div>                    
                </div>

            </div>
        </section>
        </>
    );
}
export default SectionRefundCancellationPolicy;