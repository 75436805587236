import {Link} from 'react-router-dom';

import Header from '../include/Header';
//import Heroslider from '../include/Heroslider';
import Herosliderreact from '../include/Herosliderreact';
import SectionAbout from '../include/SectionAbout';
import SectionCourse from '../include/SectionCourse';
import SuccessCandidate from '../include/SuccessCandidate';

import Footer from '../include/Footer';

import { useEffect } from 'react';
import PackagePlanList from '../include/PackagePlanList';

const margincenter={
    marginLeft: 'auto',
    marginRight: 'auto'
};

const btnsize={
    fontSize: 'x-large'
};

function Home() {

    

    return(
        <>
        {/* Hello */}
        <Header/>
        {/* <Heroslider/> */}

        
        {/* <Herosliderreact/> */}

        <PackagePlanList />

        <SectionAbout/>
        <SectionCourse/>

        <SuccessCandidate/>
        <section id="services" class="services section-bg">
            <div class="container" data-aos="fade-up">

                <div className="section-title" data-aos="fade-up">
                    <h2>About <strong>Our Institute</strong></h2>
                    <p></p>
                </div>

                <div class="row" style={margincenter}>
                <div class="col-lg-3 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div>
                <div class="col-lg-7 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box iconbox-blue">
                    <h4><a href="">आपकी सफ़लता ही हमारी प्राथमिकता है।</a></h4>
                    <h4><a href="">We prepare for Translator and Rajbhasha Adhikari...</a></h4>
                    <h4> &nbsp;</h4>
                    <Link to="/academy" className="btn btn-success" style={btnsize}>Read More</Link>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100"></div>
                </div>

            </div>
        </section>
        <Footer/>
        </>
    );
}
export default Home;